import Headroom from 'headroom.js'

export function stickyHeader() {
	var header = document.querySelector('.site-header')
	const headroom = new Headroom(header, {
		offset: {
			up: 0,
			down: 200,
		},
		tolerance: {
			up: 0,
			down: 0,
		},
		// css classes to apply
		classes: {
			// when element is initialized
			initial: 'site-header',
			// when scrolling up
			pinned: 'site-header--pinned',
			// when scrolling down
			unpinned: 'site-header--unpinned',
			// when above offset
			top: 'site-header--top',
			// when below offset
			notTop: 'site-header--not-top',
			// when at bottom of scroll area
			bottom: 'site-header--bottom',
			// when not at bottom of scroll area
			notBottom: 'site-header--not-bottom',
			// when frozen method has been called
			frozen: 'site-header--frozen',
		},
	})

	headroom.init()
}

