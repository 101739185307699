import { accordion } from './modules/accordion'
import { tabs } from './modules/tabs'
import { overlayMenu } from './modules/overlay-menu'
import { animate } from './modules/animate'
import { videoAutoplay } from './modules/video-autoplay'
import { preload } from './modules/preload'
import { stickyHeader } from './modules/sticky'
import { counter } from './modules/counter'
import { woocommerceFilters } from './modules/woocommerce'
import { tableWrapper } from './modules/table-wrapper'

preload()

videoAutoplay()

overlayMenu()

accordion()

tabs()

animate()

stickyHeader()

counter()

woocommerceFilters()

tableWrapper()