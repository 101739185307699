export function counter() {
	const counterNumsElArr = document.querySelectorAll('[data-counter-target]')

	if (!counterNumsElArr.length) return

	const observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					function counter(el, duration) {
						const targetNumber = el.dataset.counterTarget
						const startNumber = el.textContent
						const startTime = performance.now()

						function updateCounter(currentTime) {
							const elapsedTime = currentTime - startTime
							const progress = Math.min(elapsedTime / duration, 1)
							const value = Math.floor(progress * (targetNumber - startNumber))

							el.textContent = value

							if (progress < 1) {
								requestAnimationFrame(updateCounter)
							}
						}

						requestAnimationFrame(updateCounter)
					}

					counter(entry.target, 1000)

					entry.target.closest('[data-counter-number]').classList.add('scale')

					setTimeout(() => {
						entry.target.closest('[data-counter-number]').classList.add('text-primary')
					}, 850)

					observer.unobserve(entry.target)
				}
			})
		}
	)

	counterNumsElArr.forEach(counterNumEl => {
		observer.observe(counterNumEl)
	})
}
