export function woocommerceMiniCart() {
	//Toggle mini cart
	const miniCart = document.querySelector('.custom-mini-cart')
	const miniCartCurtain = document.querySelector('.curtain--cart')
	let miniCartOpen = false

	const miniCartVisibleClass = 'custom-mini-cart--visible'
	const miniCartCurtainVisibleClass = 'curtain--visible'

	function miniCartOpenHandler() {
		miniCartOpen = true
		miniCart.classList.add(miniCartVisibleClass)
		miniCartCurtain.classList.add(miniCartCurtainVisibleClass)
	}

	function miniCartCloseHandler() {
		miniCartOpen = false
		miniCart.classList.remove(miniCartVisibleClass)
		miniCartCurtain.classList.remove(miniCartCurtainVisibleClass)
	}

	document.addEventListener('keydown', function (e) {
		if (e.key == 'Escape') {
			miniCartCloseHandler()
		}
	})

	for (const element of document.querySelectorAll('[data-custom-mini-cart-toggle]')) {
		element.addEventListener('click', function (event) {
			event.preventDefault()
			if (miniCartOpen) {
				miniCartCloseHandler()
			} else {
				miniCartOpenHandler()
			}
		})
	}

	//Mini cart animation after product add
	jQuery(document.body).on('added_to_cart', function () {
		jQuery("[data-custom-mini-cart-toggle='main-link']")
			.addClass('shadow-pulse')
			.on('animationend', function () {
				jQuery(this).removeClass('shadow-pulse')
			})
	})
}

export function woocommerceFilters() {
	const filterButton = document.querySelector('[data-filters-button-toggle]')

	if (!filterButton) return // Return early if no filter button found

	const filterContent = document.querySelector('[data-filters-content]')

	const filterContentIsVisible = localStorage.filtersContent === 'visible'
	const filterContentIsHidden = localStorage.filtersContent === 'hidden'

	if (filterContentIsVisible) showFilter()
	if (filterContentIsHidden) hideFilter()

	filterButton.addEventListener('click', filterToggle)

	function hideFilter() {
		filterContent.classList.remove('filters__content--visible')
		filterButton.classList.remove('filters__button-toggle-filters--content-hidden')
		filterButton.innerText = 'Show Filters'
		localStorage.setItem('filtersContent', 'hidden')
	}

	function showFilter() {
		filterContent.classList.add('filters__content--visible')
		filterButton.classList.add('filters__button-toggle-filters--content-hidden')
		filterButton.innerText = 'Hide Filters'
		localStorage.setItem('filtersContent', 'visible')
	}

	function filterToggle() {
		if (filterButton.innerText === 'Show Filters') return showFilter()
		if (filterButton.innerText === 'Hide Filters') return hideFilter()
	}
}
